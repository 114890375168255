import Vue from "vue";
import Vuex from "vuex";

// 该指令必须在store创建之前执行
Vue.use(Vuex);

// State: 用于存储数据
const state = {
    signIsShow: true,
}

// Mutations(变化): 修改 state 中的数据
const mutations = {
    setSignIsShow(state, data) {
        state.signIsShow = data;
    }
}

// Actions(行动): 处理交互行为
const actions = {

};

// 创建并导出store
export default new Vuex.Store({
    actions,
    mutations,
    state
})