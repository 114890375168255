import Vue from 'vue'
import App from './App.vue';
import store from './store';
import router from './router'
import ElementUI from 'element-ui';
import config from './utils/config.js';
import api from './utils/api.js';
import commonFun from './utils/function.js';
import 'element-ui/lib/theme-chalk/index.css';

ElementUI.Dialog.props.lockScroll.default = false;
Vue.config.productionTip = false
Vue.use(ElementUI);


Vue.prototype.$api = api;
Vue.prototype.$config = config;
Vue.prototype.$commonFun = commonFun;

// 初始化css
import "./assets/css/reset.css";
import "./assets/iconfont/iconfont.css";
import "./assets/css/common.less";
import "./assets/css/animate.min.css";

new Vue({
  store, // 注册 store
  router,
  render: h => h(App)
}).$mount('#app')
