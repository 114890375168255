// 公共方法
import {Message,Loading} from 'element-ui';
import router from '../router'

export default {
	//弹窗
	toast(text) {
		Message({
			message:text,
			type:"error"
		})
	},
	//加载动画
	loading(){
		Loading.service();
	},
	//跳转登录
	goLogin(){
		this.toast('请先登录');
		router.push({ path: "/login" })
	}
}


	
	
