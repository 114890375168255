<template>
	<div class="footer">
		<div class="footBox">
			<div class="footli logo">
				<img src="https://image.ceekee.com/pc/tripwise/footLogo.png">
				<div class="callPhone">
					<i class="iconfont iconiphone"></i>
					<p>咨询电话：400-900-5788<br>张女士 180-5110-2396（同微信）</p>
				</div>
			</div>
			<div class="footli meau">
				<ul v-for="(item,index) in footMeau" :key="index">
					<p>{{item.title}}</p>
					<li v-for="(val,key) in item.meauLi" :key="key">
						<router-link  :to="val.url">{{val.title}}</router-link>
					</li>
				</ul>
			</div>
			<div class="footli wx">
				<div class="wxBox">
					<img src="https://image.ceekee.com/pc/secondwebsite/daguanjiawx.png">
					<p>在线咨询</p>
					<span>微信扫一扫</span>
				</div>
			</div>
		</div>
		<div class="lastBottom">Copyright 2022-2024 苏州大管家信息科技有限公司ICP备案：
			<a target="_blank" href="https://beian.miit.gov.cn/">苏ICP备2022008637-1</a>
			<img src="https://image.ceekee.com/pc/tripwise/gongan.png">
			<a target="_blank" href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32059002003925">苏公网安备 32059002003925号</a>
		</div>
	</div>
</template>

<script>
	export default {
	  name: "Footer",
	  data() {
		 return {
			 footMeau:[{
				"title": "差旅产品",
				"meauLi":[{
					"title":"一站式采购+费用管控",
					"url":"/product"
				}]
			 },{
				 "title": "解决方案",
				 "meauLi":[{
				 	"title":"资源解决方案",
				 	"url":"/resourceSolution"
				 },{
				 	"title":"数据解决方案",
				 	"url":"/dataSolution"
				 },{
				 	"title":"金融解决方案",
				 	"url":"/financeSolution"
				 },{
				 	"title":"跨平台解决方案",
				 	"url":"/crossSolution"
				 }]
			 },{
				"title": "优质服务",
				"meauLi":[{
					"title":"客户服务",
					"url":"/customerService"
				}]
			 },{
				"title": "关于我们",
				"meauLi":[{
					"title":"公司介绍",
					"url":"/aboutUs"
				},{
					"title":"加入我们",
					"url":"/joinUs"
				},{
					"title":"帮助中心",
					"url":"/help"
				}]
			 }]
		 };
	  }
	};
</script>

<style scoped lang="less">
	.footer{
		background: #060922;
		width: 100%;
		overflow-x: hidden;
		.footBox{
			width: 1600px;
			margin: 0 auto;
			padding: 64px 0;
			height: auto;
			overflow: hidden;
			.footli{
				border-right: 1px solid #707070;
				height: 265px;
				box-sizing: border-box;
				float: left;				
				&.logo{
					max-width: 380px;
					width: 25%;
					img{
						margin-top: 50px;
					}
					.callPhone{
						letter-spacing: 1px;
						margin-top: 70px;
						i{
							border-radius: 50%;
							border: 1px solid #999;
							font-size: 18px;
							color: #999;
							width: 23px;
							line-height: 23px;
							margin-top: 22px;
							text-align: center;
							float: left;
						}
						p{
							color: #999;
							margin:0 20px 0 40px;
							font-size: 16px;
							line-height: 26px;
						}
					}
				}
				&.meau{
					width: 49%;
					padding:10px 0 0 0;
					ul{
						float: left;
						text-align: center;
						color: #999;
						font-size: 22px;
						margin-left: 8%;
						&:first-child{
							margin-left: 6%;
						}
						p{
							margin-bottom: 15px;
						}
						li{
							font-size: 14px;
							line-height: 20px;
							margin-bottom: 24px;
							a{
								color: #999;
							}
						}
					}					
				}
				&.wx{
					// float: right;
					width: 16%;
					border-right: none;
					.wxBox{
						float: right;
						width: 52%;
						text-align: center;
						color: #fff;
						background: #102C4D;
						padding: 15px 3% 10px;
						margin:20px 0 0 9%;
						border-radius: 10px;
						font-size: 14px;
						img{
							width: 100%;
							margin-bottom: 10px;
						}
						span{
							font-size: 18px;
						}
					}
				}
			}
		}
		.lastBottom{
			line-height: 75px;
			border-top: 1px solid #707070;
			color: #999;
			text-align: center;
			a{
				color: #999;
				margin-right: 15px;
			}
			img{
				width: 15px!important;
				margin: -4px 5px 0 0;
			}
		}
	}	
	@media screen and (max-width:1600px) {
		.footer{
			letter-spacing: 0;
			.footBox{width: 90%;padding: 64px 0; overflow: hidden;box-sizing: border-box;}
			.footBox .footli.logo img{margin-top: 35px}
			.footBox .footli.logo .callPhone p{padding-top: 10px;}
			.footBox .footli.meau ul{margin-left: 40px;}
			.footBox .footli.logo .callPhone p{font-size: 15px;}
		}
	}	
	
	
	@media screen and (max-width:1400px) {
		.footer{
			.footBox{
				width: 95%;
				.footli.meau ul{font-size: 20px;margin-left: 5.5%;}
				.footli.meau ul:last-child{margin-right: 5%;}
				.footli.wx .wxBox{font-size: 13px;}
				.footli.wx .wxBox span{font-size: 15px;}
			}
		}
	}
	
	@media screen and (max-width:1400px) {
		.footer{
			.footBox{
				.footli.logo{width: 28%;}
				.footli.meau{width: 46%;}
				.footli.meau ul{font-size: 20px;margin-left: 4%;}
				.footli.meau ul:first-child{margin-left: 5%;}
				.footli.meau ul:last-child{margin-right: 4%;}
				.footli.logo .callPhone p{margin-right: 0;}
				.footli.wx .wxBox{padding: 15px 4% 10px;}
			} 
		} 
	}
</style>
