import Vue from 'vue'
import VueRouter from 'vue-router'
import module from "./module";

import Header from "@/components/Header";
import Footer from "@/components/Footer";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
	// meta: {
	// 	title: "差旅管家-企业商旅管理系统-首页"		
	// },
	components: { 
		header: Header,
		footer: Footer,
		default:() => import('@/views/Home.vue')
	}
  },  
  ...module,
]

const router = new VueRouter({
	// mode:"history",
	routes,

})

router.beforeEach((to, form, next) => {
    if (to.meta.title) {
		document.title = to.meta.title;
    }
	next();
});


router.afterEach((to,from,next) => {
	window.scrollTo(0,0);
});
export default router
