<template>
  <div>
    <el-dialog
        :visible="isShow"
        width="23%"
        :append-to-body="true"
        @closed="handleClosed"
        :show-close="false"
        custom-class="dialog-notify"
    >
      <div class="dialog-box">
        <div class="status-icon">
          <img src="@/assets/img/status_success.png" alt="">
        </div>
        <div class="status-text">
          <span>已提交</span>
        </div>
        <div class="tips">
          <span>工作人员将于1个工作日内联系您发放。</span>
        </div>
        <div class="footer">
          <el-button class="confirm-button" @click="handleClosed">知道了</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "DialogNotify",
  props: {
    isShow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {

    }
  },
  methods: {
    handleClosed() {
      this.$emit('closed')
    }
  }
}
</script>

<style scoped lang="less">
/deep/.dialog-notify {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
}
/deep/.el-dialog {
  border-radius: 8px !important;
  background-color: #FFFFFF;

}

/deep/.el-dialog__header {
  padding: 0 !important;
}

/deep/.el-dialog__body{
  padding: 0 !important;
}
/deep/.dialog-box{
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .status-text{
    font-size: 18px;
    margin-top: 12px;
    font-weight: 600;
    color: #000000;
  }
  .tips{
    margin-top: 8px;
    font-size: 16px;
    font-weight: 400;
    color: rgba(0,0,0,0.5);
  }
}
.status-icon{
  width: 36px;
  height: 36px;
  border-radius: 50%;

  img{
    width: 100%;
    height: 100%;
  }
}

.footer {
  margin-top: 20px;
  display: flex;
  justify-content: center;

  /deep/.confirm-button{
    color: #FFFFFF;
    background: #1022A2;
    border-radius: 4px;
  }
}
</style>