<template>
  <div class="AliyunCaptcha">
    <div id="captcha-element"></div>
    <div id="captcha-button" v-show="false"></div>
  </div>
</template>

<script>
export default {
  name: "AliyunCaptcha",
  components: {},
  data() {
    return {
      captcha: null,
      captchaButton: null,
      phone: "",
      verifyParam: {}, //滑动验证码参数
    };
  },
  mounted() {
    this.initCaptcha();
  },
  beforeDestroy() {
    this.captchaButton = null;
    document.getElementById("aliyunCaptcha-mask")?.remove();
    document.getElementById("aliyunCaptcha-window-popup")?.remove();
  },
  methods: {
    // 唤起验证码弹窗
    handleCaptchaButton(phone) {
      this.phone = phone;
      this.captchaButton.click();
    },
    initCaptcha() {
      this.captchaButton = document.getElementById("captcha-button");
      initAliyunCaptcha({
        SceneId: "16jrczu4", // 场景ID。根据步骤二新建验证场景后，您可以在验证码场景列表，获取该场景的场景ID
        prefix: "1nhit5", // 身份标。开通阿里云验证码2.0后，您可以在控制台概览页面的实例基本信息卡片区域，获取身份标
        mode: "popup", // 验证码模式。popup表示要集成的验证码模式为弹出式。无需修改
        element: "#captcha-element", //页面上预留的渲染验证码的元素，与原代码中预留的页面元素保持一致。
        button: "#captcha-button", // 触发验证码弹窗的元素。button表示单击登录按钮后，触发captchaVerifyCallback函数。您可以根据实际使用的元素修改element的值
        captchaVerifyCallback: this.captchaVerifyCallback, // 业务请求(带验证码校验)回调函数，无需修改
        onBizResultCallback: this.onBizResultCallback, // 业务请求结果回调函数，无需修改
        getInstance: this.getInstance, // 绑定验证码实例函数，无需修改
        slideStyle: {
          width: 360,
          height: 40,
        }, // 滑块验证码样式，支持自定义宽度和高度，单位为px。其中，width最小值为320 px
        language: "cn", // 验证码语言类型，支持简体中文（cn）、繁体中文（tw）、英文（en）
        region: "cn", //验证码示例所属地区，支持中国内地（cn）、新加坡（sgp）
      });
    },
    getInstance(instance) {
      this.captcha = instance;
    },
    async captchaVerifyCallback(captchaVerifyParam) {
      this.verifyParam = captchaVerifyParam;
      return new Promise((resolve, reject) => {
        this.$api
          .sendSms({
            captchaVerifyParam: JSON.parse(captchaVerifyParam),
            PlatId: "1003",
            Version: "5.0.3",
            phone: this.phone,
            sendtype: 7,
          })
          .then((res) => {
            //短信发送成功
            this.$notify({
              title: "发送成功",
              message: "短信发送成功，请注意查收",
              type: "success",
            });
            resolve({
              captchaResult: true,
              bizResult: true,
            });
          })
          .catch((res) => {
            // Code为1001时，代表验证码校验未通过
            if (res.Code === "1001") {
              resolve({
                captchaResult: false,
                bizResult: false,
              });
            } else {
              resolve({
                captchaResult: true,
                bizResult: false,
              });
            }
          });
      });
    },

    onBizResultCallback(bizResult) {
      this.$emit("BizResult", { data: !!bizResult, captchaVerifyParam: JSON.parse(this.verifyParam) });
    },
  },
};
</script>
