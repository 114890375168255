//  配置文件 

// 开发环境或者默认配置
let config = {
  request_url: 'https://api.ceekeeapi.com/tripwise/',// 'http://192.168.1.231:1010/', // 请求地址公共url 
  request_timeout: 10, // 请求超时时间  单位s,
  appointment_url: '/appointment', //预约页面
  phone_number: '400-900-5788',//400电话
  sign_url: '/sign' // 注册页面
}

// 非开发环境下配置
if (process.env.NODE_ENV != 'development') {
  config.request_url = 'https://api.ceekeeapi.com/tripwise/'; // 请求地址
}

export default config