// http 请求
import config from './config.js';
import commonFun from './function.js';
import axios from 'axios';
let { request_url, request_timeout } = config;
let { goLogin, toast, loading } = commonFun;

export default function request (data) {
  return new Promise((resolve, reject) => { // resolve(res) 抛出成功  reject(err)抛出失败  【抛出时可传递参数】
    // 开启加载动画
    if (data.loading) {
      loading();
    }
    httpRrequest(data, resolve, reject)
  });
}

// 请求拦截器封装 
let httpRrequest = (data, resolve, reject) => {
  let _url;
  //请求地址  如果请求地址有https 或者有 http 则不添加公共请求头
  if (data && data.url) {
    if (data.url.indexOf('http://') < 0 && data.url.indexOf('https://') < 0) {
      _url = request_url + data.url;
    } else {
      _url = data.url;
    }
  } else {
    toast('请求地址不能为空');
    return;
  }

  let requestParam = {
    url: _url,
    timeout: request_timeout * 1000, // 请求超时时间
    headers: {
      'content-type': 'application/json; charset=utf-8'
    },
    data: data.param,
    method: data.method,
  }

  const _token = localStorage.token;
  if (_token) requestParam.headers.Authorization = _token;

  if (data.auth && !_token) {   //接口需要验证登录状态
    goLogin();
    return;
  }

  //发送请求
  axios(requestParam).then(function (res) {
    if (res.status !== 200) {
      toast(`服务器出现错误：错误代码：${res.status}`)
      resolve();
      return
    }
    if (res.data.Code !== '0000') {
      reject(res.data);
      toast(res.data.Msg);
    } else {
      resolve(res.data);
    }
  }).catch(function (error) {
    reject(error);
    toast('无法连接服务器');
  });
}

['options', 'get', 'post', 'put', 'head', 'delete', 'trace', 'connect'].forEach((method) => {
  request[method] = (url, data, loading, auth) => request({
    'url': url,
    'param': data ? data : {},
    'method': method,
    'loading': loading ? true : false,
    'auth': auth ? true : false
  })
});