import Header from "@/components/Header";
import Footer from "@/components/Footer";

export default [
{
	path: '/aboutUs',
	component: () =>import("@/views/About.vue"),
	children:[{
		path: '/aboutUs',
		name: 'aboutUs',
		// meta: {
		// 	title: "差旅管家-企业商旅管理系统-公司介绍",
		// },
		components: {
			header: Header,
			footer: Footer,
			default:() => import("@/views/about/AboutUs.vue")
		}
	},{
		path: '/joinUs',
		name: 'joinUs',
		// meta: {
		// 	title: "差旅管家-企业商旅管理系统-加入我们"
		// },
		components: {
			header: Header,
			footer: Footer,
			default:() => import("@/views/about/JoinUs.vue")
		}
	},{
		path: '/help',
		name: 'help',
		// meta: {
		// 	title: "差旅管家-企业商旅管理系统-帮助中心"
		// },
		components: {
			header: Header,
			footer: Footer,
			default:() => import("@/views/about/Help.vue")
		}
	}]
},{
	path: '/customerService',
	name: 'customerService',
	// meta: {
	// 	title: "差旅管家-企业商旅管理系统-客户服务"
	// },
	components: {
		header: Header,
		footer: Footer,
		default:() => import("@/views/CustomerService.vue")
	}
},{
	path: '/appointment',
	name: 'appointment',
	// meta: {
	// 	title: "差旅管家-企业商旅管理系统-登录预约",
	// 	topfiexd: true
	// },
	components: {
		header: Header,
		footer: Footer,
		default:() => import("@/views/Appointment.vue")
	}
},{
		path: '/sign',
		name: 'appointment',
		components: {
			header: Header,
			footer: Footer,
			default:() => import("@/views/Sign.vue")
		}
	},{
	path: '/resourceSolution',
	component: () =>import("@/views/Solution.vue"),
	children:[{
		path: '/dataSolution',
		name: 'dataSolution',
		// meta: {
		// 	title: "差旅管家-企业商旅管理系统-数据解决方案",
		// },
		components: {
			header: Header,
			footer: Footer,
			default:() => import("@/views/solution/DataSolution.vue")
		}
	},{
		path: '/resourceSolution',
		name: 'resourceSolution',
		// meta: {
		// 	title: "差旅管家-企业商旅管理系统-资源解决方案",
		// },
		components: {
			header: Header,
			footer: Footer,
			default:() => import("@/views/solution/ResourceSolution.vue")
		}
	}, {
		path: '/financeSolution',
		name: 'financeSolution',
		// meta: {
		// 	title: "差旅管家-企业商旅管理系统-金融解决方案",
		// },
		components: {
			header: Header,
			footer: Footer,
			default:() => import("@/views/solution/FinanceSolution.vue")
		}
	}, {
		path: '/crossSolution',
		name: 'crossSolution',
		// meta: {
		// 	title: "差旅管家-企业商旅管理系统-跨平台解决方案",
		// },
		components: {
			header: Header,
			footer: Footer,
			default:() => import("@/views/solution/CrossSolution.vue")
		}
	}]
},{
	path: '/product',
	name: 'product',
	// meta: {
	// 	title: "差旅管家-企业商旅管理系统-产品",
	// },
	components: {
		header: Header,
		footer: Footer,
		default:() => import("@/views/Product.vue")
	}
}];
