//api 接口处理
import request from "./request.js";
export default {
	//发送验证码
	sendSms(param) {			
		return request.post("b2b/Common/SendTravelVerificationCode",param);
	},
	//预约体验
	experience(param) {
		return request.post("b2b/Customer/ApplyBusinessTravelExperienceAccount",param);
	}
	
}